import { formatDate } from '@helpers';
import { filter, map } from 'lodash';
import get from 'lodash/get';
import { defineStore } from 'pinia';
import short from 'short-uuid';
import { computed, ref, reactive } from 'vue';

import useFileUploader from '~/composables/useFileUploader';
import { DISPLAY_ORDER_RESOLUTIONS } from '~/support/constants';
import { ClaimService } from '~/support/services';

export const DEFAULT_CLAIM = {
  customer: {},
  fulfillments: {},
  resolution: {
    desiredMethod: null,
    updatedShippingAddress: null,
  },
  signature: null,
};

export const useClaimStore = defineStore('claim', () => {
  const state = reactive({
    claim: DEFAULT_CLAIM,
  });
  const currentShipmentId = ref(null);
  const config = useRuntimeConfig();
  const service = new ClaimService(config.public.apiBaseUrl);
  const { uploadFiles } = useFileUploader();

  async function findClaimById(id) {
    return service.findClaimById(id);
  }

  async function findClaimSession(token) {
    return service.findClaimSession(token);
  }

  async function sendClaimMessage(content, sourceOrderId, customerId) {
    let medias = [];

    if (content.files.length > 0) {
      medias = await uploadFiles(content.files);
    }

    const claimMessageInput = {
      claimId: state.claim.id,
      medias,
      messageContent: content.messageContent,
      senderId: customerId,
      senderName: state.claim.customer,
      senderType: 'customer',
      storeId: state.claim.store,
    };

    await createClaimMessage(claimMessageInput, state.claim.store, sourceOrderId);

    const newMessages = await loadClaimMessages(state.claim.id, sourceOrderId);

    state.claim.messages = newMessages;
  }

  async function createClaimMessage(data, storeId, sourceOrderId) {
    return service.createClaimMessage(data, storeId, sourceOrderId);
  }

  async function loadClaimMessages(claimId, sourceOrderId) {
    return service.loadClaimMessages(claimId, sourceOrderId);
  }

  function addFulfillmentIssues(id, issues) {
    const issuesWithIds = issues.map(issue => ({ ...issue, id: short.generate() }));

    if (!state.claim.fulfillments[id]) {
      state.claim.fulfillments[id] = {
        id: id,
        issues: issuesWithIds,
      };
    } else {
      /**
       * Remove the issues for the sourceItemId that are already in the store.
       */
      state.claim.fulfillments[id].issues = filter(
        state.claim.fulfillments[id].issues,
        ({ sourceItemId }) => !map(issues, 'sourceItemId').includes(sourceItemId),
      );

      /**
       * Add the new issues for that sourceItemId to the store.
       */
      state.claim.fulfillments[id].issues = state.claim.fulfillments[id].issues.concat(issuesWithIds);
    }
  }

  function setClaim(claimData) {
    state.claim = claimData;
  }

  /**
   * @param {Array.<string>} fulfillments
   * sets the fulfillment object in the store
   * this allows for the routing object to loop through the fulfillments with issues
   *
   * @returns {void}
   */
  function setFulfillmentState(fulfillments) {
    fulfillments.forEach(id => {
      state.claim.fulfillments[id] = {
        id,
        issues: [],
      };
    });
  }

  /**
   * @param {string} id
   * sets the current shipment id, this is used to keep track of the current shipment issues.
   *
   * @returns {void}
   */
  function setCurrentShipmentId(id) {
    currentShipmentId.value = id;
  }

  /**
   * Sets the desired resolution for the claim.
   * @enum {string} resolution
   */
  function setRequestedResolution(resolution) {
    state.claim.resolution.desiredMethod = resolution;
  }

  /**
   * setShippingAddress
   * updates the claim.resolution.updatedShippingAddress with the provided address
   * @param {Object} address
   */
  function setShippingAddress(address) {
    state.claim.resolution.updatedShippingAddress = address;
  }

  /**
   * Sets the whole shipment issue. This allows us to persist data between the shipment type and the details
   * We have to set details as well so that we can loop through and add each item with the same issue
   * @param {string} id
   * @param {string} type
   */
  function setShipmentIssueType(id, type) {
    state.claim.fulfillments[id].shipmentIssue = type;
  }

  /**
   * Sets the details for a shipment issue
   * this is global and is separate from the issues. This is just for data persistence
   * @param {string} id
   * @param {string} details
   */
  function setShipmentIssueDetails(id, details) {
    state.claim.fulfillments[id].details = details;
  }

  function addSignature(data) {
    state.claim.signature = data;
  }

  function deleteIssueByFulfillmentAndIssue(fulfillmentId, issueId) {
    state.claim.fulfillments[fulfillmentId].issues = state.claim.fulfillments[fulfillmentId].issues.filter(
      ({ id }) => id !== issueId,
    );
  }

  function getIssuesByFulfillmentAndProduct(fulfillmentId, sourceItemId) {
    return get(state.claim.fulfillments, [fulfillmentId, 'issues'], []).filter(
      ({ sourceItemId: id }) => id === sourceItemId,
    );
  }

  function submitClaim() {
    return service.createClaim({
      claim: state.claim,
      ...state.claim.customer,
    });
  }

  function setCustomer({ email, sourceOrderId }) {
    const customer = {
      email,
      sourceOrderId,
    };

    state.claim = { ...DEFAULT_CLAIM, ...state.claim, customer };
  }

  function reset() {
    state.claim.fulfillments = {};
    state.claim.resolution.updatedShippingAddress = null;
    state.claim.signature = null;
  }

  /**
   * @typedef {Object} FoundationFulfillment
   * @property {string} id
   * @property {Array.<Object>} issues
   *
   * @returns {Array.<FoundationFulfillment>}
   */
  const unfinishedFulfillments = computed(() => {
    return Object.values(state.claim.fulfillments).filter(({ issues }) => issues.length === 0);
  });

  const messages = computed(() => {
    if (!state.claim.messages) {
      return [];
    }

    return state.claim.messages.map(message => {
      return {
        ...message,
        date: formatDate(message.createdAt),
        // image: 'https://picsum.photos/200',
        key: message.id,
        medias: message.medias || [],
        message: message.messageContent,
        senderType: message.senderType,
        type: 'message',
        username: message.senderName,
      };
    });
  });

  const resolution = computed(() => {
    return state.claim.resolution ? state.claim.resolution : null;
  });

  const currentShipment = computed(() => {
    return state.claim.fulfillments[currentShipmentId.value];
  });

  const currentShipmentIssues = computed(() => {
    return currentShipment.value?.issues || [];
  });

  const allIssues = computed(() => {
    return Object.values(state.claim.fulfillments).reduce((all, { issues }) => all.concat(issues), []);
  });

  const requestedResolution = computed(() => DISPLAY_ORDER_RESOLUTIONS[state.claim.resolution.desiredMethod]);

  return {
    addFulfillmentIssues,
    addSignature,
    allIssues,
    claim: state.claim,
    createClaimMessage,
    currentShipment,
    currentShipmentId,
    currentShipmentIssues,
    deleteIssueByFulfillmentAndIssue,
    findClaimById,
    findClaimSession,
    getIssuesByFulfillmentAndProduct,
    loadClaimMessages,
    messages,
    requestedResolution,
    reset,
    resolution,
    sendClaimMessage,
    setClaim,
    setCurrentShipmentId,
    setCustomer,
    setFulfillmentState,
    setRequestedResolution,
    setShipmentIssueDetails,
    setShipmentIssueType,
    setShippingAddress,
    submitClaim,
    unfinishedFulfillments,
  };
});
